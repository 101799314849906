import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

//import Button from "@material-ui/core/Button"
import { Link } from 'gatsby';

import 'bootstrap/dist/css/bootstrap.min.css';


 import Layout from '../components/layout';
import SignInForm from '../components/SignIn';
import { SignUpLink } from '../components/SignUp';
import { PasswordForgetLink } from '../components/PasswordForget';

const SignInPage = () => (
  <>
    <SignInForm />
  </>
);

export default () => (
  <Layout>
    <SignInPage />
  </Layout>
);
 
